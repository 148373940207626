<template>
  <div>
    <b-link
      id="show-btn click"
      @click="$bvModal.show('upload')"
    >
      <b-button
        variant="primary"
        size="sm"
        class="mr-1 text-nowrap"
      >
        {{ 'Subir Archivos' }}
      </b-button>
    </b-link>
    <b-modal
      id="upload"
      :hide-footer="true"
      size="lg"
    >
      <validation-observer ref="upload">
        <b-card-title
          title-tag="h1"
          class="font-weight-bolder mb-1 text-center"
        >
          Subir Archivos
        </b-card-title>
        <div class="asignar-trabajos-modal">
          <b-form
            class="mt-2"
            ref="errorField"
            @submit.prevent="handleSubmitupload"
            @keydown.enter="handleSubmitupload"
          >
            <b-container class="pl-3 pr-3">
              <b-row
                class="justify-content-center middle"
                align-v="center"
              >
                <b-col lg="8">
                  <b-form-group
                    :label="'Albarán (PDF)'"
                    label-for="albaran"
                  >
                    <ImageDropzone
                      ref="albaran"
                      max-files="1"
                    />
                  </b-form-group>

                </b-col>
                <b-col lg="8">
                  <b-form-group
                    :label="'Factura (PDF)'"
                    label-for="factura"
                  >
                    <ImageDropzone
                      ref="factura"
                      max-files="1"
                    />
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row class="text-center mb-3">
                <b-col>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-1 mr-1 text-uppercase"
                  >
                    {{ 'Actualizar' }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import {
  BButton,
  BCardTitle,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BLink,
  BRow,
} from 'bootstrap-vue'

export default {
  name: 'UploadPDF',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    ValidationObserver,
    ImageDropzone,
    BLink,
    BCardTitle,
    BContainer,
  },
  props: {
    idOrder: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      albaran: '',
      factura: '',
      tipoUpload: '',
    }
  },
  methods: {
    ...mapActions({
      upload: 'orders/changeArchives',
    }),
    handleSubmitupload() {
      this.$refs.upload.validate().then(success => {
        if (success) {
          console.log(this.$refs.albaran)
          console.log(this.$refs.factura)
          const data = this.createFormDataUpload()
          if (data) {
            this.upload({ order: data })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    createFormDataUpload() {
      const data = this.$refs.albaran.getFormData('albaran')
      this.$refs.factura.getFormData('factura', data)
      data.append('order_id', this.idOrder)
      return data
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
